import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import { media } from '../../ui/common';
import { colors, colorsBar } from '../../../../ui/common';

export const Button = styled.button.attrs(() => ({
  type: 'button',
}))`
  background: ${(props) => (props.active ? colors.color07 : 'transparent')};
  display: block;
  box-sizing: border-box;
  color: ${colorsBar.blueDark};
  font-size: 13px;
  line-height: 1.7;
  flex: 1 0 0;
  position: relative;
  outline: none;
  border: none;
  padding: 12px 6px;
  h5 {
    margin: 4px 25px 0;
    font-weight: 500;
    font-size: 14px;
  }
  svg {
    position: absolute;
    left: 7px;
    top: 5px;
  }
  span {
    display: block;
  }
  :nth-child(2n) {
    border-left: 1px solid rgba(2, 112, 109, 0.6);
  }
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;
export const Switch = styled.div`
  display: flex;
  border: 1px solid ${colorsBar.blueDark};
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  margin: 12px 0 40px;
  ${media.phone`
    margin-bottom: 48px;
  `};
`;

function SwitchButton({ children, active, onClick, disabled }) {
  return (
    <Button active={active} onClick={onClick} disabled={disabled}>
      {active && <Checked />}
      {children}
    </Button>
  );
}
export default SwitchButton;

function Checked() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
      width="26"
      height="26"
    >
      <path
        d="M26 13c0 7.18-5.82 13-13 13S0 20.18 0 13 5.82 0 13 0s13 5.82 13 13z"
        fill={colorsBar.blue}
      />
      <path
        d="M18.854 9.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-4-4a.5.5 0 01.708-.708l3.646 3.647 6.646-6.647a.5.5 0 01.708 0z"
        fill="#fff"
      />
    </svg>
  );
}

SwitchButton.propTypes = {
  active: PropType.bool,
  children: PropType.node,
  onClick: PropType.func,
  disabled: PropType.bool,
};

SwitchButton.defaultProps = {
  active: false,
  children: null,
  onClick: null,
  disabled: false,
};
