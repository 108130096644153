export default {
  evachill: {
    color: 'specifications.color.evachill.blue',
    power: 'specifications.power.evachill',
    cooling: 'specifications.cooling.evachill',
    volume: 'specifications.volume.evachill',
    noise: 'specifications.noise.evachill',
    size: 'specifications.size.evachill',
    weight: 'specifications.weight.evachill',
    tank: 'specifications.tank.evachill',
    cycle: 'specifications.cycle.evachill',
    cartridge: 'specifications.cartridge.evachill',
    area: 'specifications.area.evachill',
    energy: 'specifications.energy.evachill',
    plugs: 'specifications.plugs.evachill',
    supply: 'specifications.supply.evachill.blue',
    accessories: 'specifications.accessories.evachill',
  },
};
