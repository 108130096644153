import isEqual from 'lodash/isEqual';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ItemType from '../../../../domain/item-type';
import {
  getItemsInfo,
  getColors,
  getPlugs,
} from '../../../../domain/items-list';
import areMapsEqual from '../../../../utils/are-maps-equal';
import ActionType from '../../../../store/action-type';
import useAvailablePlug from '../../../../hooks/use-available-plug';

const handlePlug = ({
  itemsInfo,
  preferDeviceInsteadOfPackage,
  color,
  plug,
}) => {
  const itemIndex = itemsInfo.available.findIndex(
    (item) => item.color === color && item.plug === plug
  );

  let selectedIndex;
  let deviceInsteadOfPackage = preferDeviceInsteadOfPackage;
  if (itemIndex === -1) {
    selectedIndex = null;
    deviceInsteadOfPackage = null;
  } else {
    selectedIndex = itemIndex;
    const selectedItem = itemsInfo.available[selectedIndex];
    if (selectedItem.type === ItemType.OrphanDevice) {
      deviceInsteadOfPackage = true;
    } else if (selectedItem.type === ItemType.PackageOnly) {
      deviceInsteadOfPackage = false;
    }
  }

  return { plug, selectedIndex, deviceInsteadOfPackage };
};

const handleColor = ({
  itemsInfo,
  preferDeviceInsteadOfPackage,
  color,
  getAvailablePlug,
}) => {
  const availableItemsByColor = itemsInfo.available.filter(
    (item) => item.color === color
  );

  const availableWrapped = availableItemsByColor.map((v) => ({
    ...v,
    title: v.plug,
  }));

  const plugItem = getAvailablePlug(availableWrapped);

  let plug = plugItem ? plugItem.title : null;

  if (!plug) {
    plug =
      availableItemsByColor.length === 0 ? null : availableItemsByColor[0].plug;
  }

  return handlePlug({
    itemsInfo,
    preferDeviceInsteadOfPackage,
    color,
    plug,
  });
};

const handleList = (
  list,
  productName,
  preferDeviceInsteadOfPackage,
  getAvailablePlug
) => {
  const itemsInfo = getItemsInfo(list, productName);
  const colors = getColors(itemsInfo);
  const plugs = getPlugs(itemsInfo);
  const color = 'blue';
  const { plug, selectedIndex, deviceInsteadOfPackage } = handleColor({
    itemsInfo,
    preferDeviceInsteadOfPackage,
    color,
    getAvailablePlug,
  });

  return {
    ...itemsInfo,
    colors,
    plugs,
    color,
    plug,
    selectedIndex,
    deviceInsteadOfPackage,
  };
};

export default function useItemsData(productName) {
  const list = useSelector((state) => state.items.list);
  const dispatch = useDispatch();
  const preferDeviceInsteadOfPackage = useSelector(
    (state) => state.preferences.deviceInsteadOfPackage
  );
  const getAvailablePlug = useAvailablePlug();
  const [data, setData] = useState(
    handleList(
      list,
      productName,
      preferDeviceInsteadOfPackage,
      getAvailablePlug
    )
  );
  useEffect(() => {
    setData((prev) => {
      const updated = handleList(
        list,
        productName,
        prev.deviceInsteadOfPackage || preferDeviceInsteadOfPackage,
        getAvailablePlug
      );
      const equal =
        updated.available.length === prev.available.length &&
        updated.available.every((v, i) => isEqual(v, prev.available[i])) &&
        areMapsEqual(updated.colorsMapping, prev.colorsMapping);
      return equal ? prev : updated;
    });
  }, [list]);

  const setColor = (to) => {
    setData((prev) => {
      const { plug, selectedIndex, deviceInsteadOfPackage } = handleColor({
        itemsInfo: prev,
        preferDeviceInsteadOfPackage,
        color: to,
        getAvailablePlug,
      });

      return {
        ...prev,
        color: to,
        plug,
        selectedIndex,
        deviceInsteadOfPackage,
      };
    });
  };

  const setPlug = (to) => {
    setData((prev) => {
      const { selectedIndex, deviceInsteadOfPackage } = handlePlug({
        itemsInfo: prev,
        preferDeviceInsteadOfPackage,
        color: prev.color,
        plug: to,
      });

      return {
        ...prev,
        plug: to,
        selectedIndex,
        deviceInsteadOfPackage,
      };
    });
  };

  const toDevice = () => {
    dispatch({ type: ActionType.Preferences.PreferDeviceInsteadOfPackage });
    setData((prev) => ({ ...prev, deviceInsteadOfPackage: true }));
  };
  const toPackage = () => {
    dispatch({ type: ActionType.Preferences.PreferPackageInsteadOfDevice });
    setData((prev) => ({ ...prev, deviceInsteadOfPackage: false }));
  };

  return { data, setPlug, setColor, toDevice, toPackage };
}
