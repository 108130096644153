import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container, fonts } from '../../ui/common';
import {
  Tabs,
  Tab,
  List,
  Block,
  Title,
  Text,
  Images,
  Wrap,
} from '../../Specifications/styled';
import data from './data';
import { Title as BaseTitle } from '../FAQ/styled';

const Section = styled.section`
  background-color: #f9f7f6;
  padding-top: 40px;
`;

const SectionTitle = styled(BaseTitle)`
  margin-top: 0;
  font-size: 36px;
  line-height: 54px;
  font-family: ${fonts.neueMachina.name};
  font-weight: ${fonts.neueMachina.weight.regular};
  text-align: center;
`;

function Specifications({ images, type }) {
  const [active, setActive] = useState(0);
  const options = data[type];
  return (
    <Section>
      <Container>
        <SectionTitle>
          <FormattedMessage id="specifications.title" />
        </SectionTitle>
        <Tabs>
          <Tab
            chillBlueTheme
            active={active === 0}
            onClick={() => setActive(0)}
          >
            <FormattedMessage id="specifications.tab1" />
          </Tab>
          <Tab
            chillBlueTheme
            active={active === 1}
            onClick={() => setActive(1)}
          >
            <FormattedMessage id="specifications.tab2" />
          </Tab>
        </Tabs>
        {active === 0 ? (
          <List>
            {Object.keys(options).map((key, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Block key={index}>
                <Title chillBlueTheme>
                  <FormattedMessage id={`specifications.${key}.title`} />
                </Title>
                <Text chillBlueTheme>
                  <FormattedMessage id={options[key]} />
                </Text>
              </Block>
            ))}
          </List>
        ) : (
          <div
            style={{
              paddingBottom: '30px',
            }}
          >
            <Images>
              {images.map((item, key) => (
                // eslint-disable-next-line react/no-array-index-key
                <Wrap key={key}>
                  <GatsbyImage
                    image={item}
                    imgStyle={{
                      objectFit: 'contain',
                    }}
                  />
                </Wrap>
              ))}
            </Images>
          </div>
        )}
      </Container>
    </Section>
  );
}

export default Specifications;

Specifications.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any),
  type: PropTypes.string.isRequired,
};

Specifications.defaultProps = {
  images: [],
};
