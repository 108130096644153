import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import YoutubeVideo from '../../../YoutubeVideo';
import { Wrapper } from '../../../Gallery/styled';
import { colorsBar, media } from '../../../../ui/common';

const SliderContainer = styled.div`
  ${media.tablet`
    .swiper-wrapper {
      display: grid;
      gap: 20px;
      grid-template-rows: 2fr;
      grid-template-columns: 1fr 1fr;  
    }
    .swiper-slide:first-child {
      grid-column: 1 / span 2;
      grid-row: 1 / span 2;
    }
  `}
`;

const Pagination = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 3;
  .swiper-pagination-bullet {
    background-color: ${colorsBar.white};
    border: 1px solid ${colorsBar.blue};
    height: 12px;
    width: 12px;
  }
  .swiper-pagination-bullet-active {
    background-color: ${colorsBar.blue};
  }
`;

function Gallery({ color, youtube, fluids }) {
  // TODO: IMPORTANT! White is by default if redux items is not available on first load.
  const colorSelected = color || 'white';
  const galleryRef = useRef();
  const galleryPagination = useRef();
  const toLeftRef = useRef();
  const toRightRef = useRef();

  useEffect(() => {
    let gallerySwiper;

    if (window.innerWidth < 768) {
      // eslint-disable-next-line no-new
      gallerySwiper = new Swiper(galleryRef.current, {
        spaceBetween: 12,
        loop: true,
        navigation: {
          nextEl: toRightRef.current,
          prevEl: toLeftRef.current,
        },
        pagination: {
          el: galleryPagination.current,
        },
        grabCursor: true,
        preloadImages: true,
      });
    }

    return () => {
      if (window.innerWidth < 768) {
        gallerySwiper.destroy();
      }
    };
  }, [colorSelected, youtube, fluids]);

  const images = fluids.get('blue') || [];

  return (
    <Wrapper>
      <SliderContainer className="swiper-container" ref={galleryRef}>
        <Pagination ref={galleryPagination} />
        <div className="swiper-wrapper">
          {images.map((fluid, i) => (
            <GatsbyImage
              image={fluid}
              loading="eager"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className="swiper-slide"
              alt=""
            />
          ))}
          {youtube && (
            <div className="swiper-slide">
              <YoutubeVideo
                inSlider
                chillBlueTheme
                url={youtube.url}
                aspectRatio={youtube.aspectRatio}
              />
            </div>
          )}
        </div>
      </SliderContainer>
    </Wrapper>
  );
}

Gallery.defaultProps = {
  color: 'blue',
  youtube: null,
};

Gallery.propTypes = {
  // eslint-disable-next-line react/require-default-props
  color: PropTypes.string,
  youtube: PropTypes.exact({
    url: PropTypes.string.isRequired,
    aspectRatio: PropTypes.number.isRequired,
    fluid: PropTypes.object.isRequired,
  }),
  fluids: PropTypes.instanceOf(Map).isRequired,
};

export default Gallery;
