import styled from 'styled-components';
import { colorsBar } from '../../../../ui/common';
import { Headline, media, fonts } from '../../ui/common';

export const SectionTitle = styled(Headline)`
  color: ${colorsBar.blue};
`;
export const Section = styled.section`
  padding: 92px 0;
  background: #fff;
  ${media.tablet`
    padding: 120px 0;
  `}
  ${media.laptop`
    padding: 130px 0;
  `}
`;
export const Row = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
`;
export const List = styled.div`
  margin: 40px auto 0;
`;
export const Block = styled.div`
  padding: 4px 0;
  font-size: 16px;
  line-height: 1.85;
  border-bottom: 1px solid #0594b35c;
  &:first-of-type {
    border-top: 1px solid #0594b35c;
  }
  ${media.laptop`
      padding: 13px 0;
  `}
`;
export const Title = styled.h3`
  font-size: 16px;
  margin: 16px 0;
  color: ${colorsBar.blueDark};
  font-family: ${fonts.montserrat.name};
  font-weight: ${fonts.montserrat.weight['600']};
`;
export const Text = styled.div`
  max-height: ${(props) => (props.active ? '200vh' : 0)};
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: 0.1s ease-in;
  overflow: hidden;
  color: ${colorsBar.blueDark};
  line-height: 1.5;
  font-family: ${fonts.montserrat.name};
  font-weight: ${fonts.montserrat.weight['400']};
  img {
    max-width: 100%;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;
