import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ItemType from '../../../../domain/item-type';
import SliderGallery from './SliderGallery';
import { Container } from '../../ui/common';
import SwitchButton, { Switch } from './SwitchButton';
import Plugs from './Plugs';
import OptionsList from './OptionsList';
import {
  Section,
  Headline,
  Row,
  Block,
  Desc,
  Text,
  Price,
  Button,
  PriceWrapBottom,
  PriceWrapButton,
  DiscountPrice,
  DiscountBlock,
  PriceOriginal,
} from './styled';
import { selectSortedPlugs } from '../../../../store/selectors/countries';

import useRatingSnippet from '../../../../hooks/use-rating-snippet';
import Divider from '../../../../ui/divider';
import { getReadablePrice } from '../../../../domain/items-list';
import Context from './Context';
import { propTypes, defaultProps } from './Props';
import { CoolPrivilegeCheckBox } from '../../../Basket/CoolPrivilege';
import Features from '../Features';

const Main = React.forwardRef((props, ref) => {
  const { showDesc, priceMode, discount } = props;
  const sortedPlugs = useSelector(selectSortedPlugs);
  const {
    onChange,
    productName,
    coolPrivilegeRef,
    addItemToCart,
    setPlugByTitle,
    price,
    priceData,
    localizedDiff,
    localizedPackagePrice,
    localizedDevicePrice,
    item,
    itemsData,
    toDevice,
    toPackage,
    youtube,
    fluids,
    data,
  } = useContext(Context);

  useRatingSnippet();

  const plugs = itemsData.plugs
    .sort((a, b) => sortedPlugs.indexOf(a) - sortedPlugs.indexOf(b))
    .map((plug) => ({ title: plug, available: true }));

  return (
    <Section>
      <Container>
        <Row>
          <SliderGallery
            color={itemsData.color}
            youtube={youtube}
            fluids={fluids}
          />
          <Block>
            {data.hasRating && (
              <div className="ruk_rating_snippet" data-sku={data.productId} />
            )}
            <Headline>
              <FormattedMessage id="evachill.blue.product.title" />
            </Headline>
            {showDesc ? (
              <>
                <Desc>
                  <FormattedMessage id={data.desc} />
                </Desc>
                <Text>
                  <FormattedMessage id="evachill.blue.product.decription" />
                </Text>
              </>
            ) : (
              <Divider />
            )}
            <Features />
            {itemsData.plug !== null && (
              <Plugs
                list={plugs}
                active={itemsData.plug}
                setPlug={setPlugByTitle}
              />
            )}
            {item && (
              <>
                <Switch>
                  <SwitchButton
                    active={itemsData.deviceInsteadOfPackage}
                    disabled={item.type === ItemType.PackageOnly}
                    onClick={toDevice}
                  >
                    <FormattedMessage
                      id="product.title.aircooler"
                      tagName="h5"
                    />
                    <span>{localizedDevicePrice}</span>
                  </SwitchButton>
                  <SwitchButton
                    active={!itemsData.deviceInsteadOfPackage}
                    disabled={item.type === ItemType.OrphanDevice}
                    onClick={toPackage}
                  >
                    <FormattedMessage
                      id="product.title.aircooler_cartridge"
                      tagName="h5"
                    />
                    {item.type !== ItemType.OrphanDevice && (
                      <>
                        <span>{localizedPackagePrice}</span>
                        <FormattedMessage
                          id="product.title.save"
                          values={{ val: localizedDiff }}
                          tagName="span"
                        />
                      </>
                    )}
                  </SwitchButton>
                </Switch>
                <CoolPrivilegeCheckBox
                  onChange={onChange}
                  productName={productName}
                  productPrice={price}
                  ref={coolPrivilegeRef}
                  chillBlueTheme
                />
                {priceMode === 'top' && discount ? (
                  <DiscountBlock>
                    <PriceOriginal>{price}</PriceOriginal>
                    <DiscountPrice>
                      {getReadablePrice({
                        ...priceData,
                        amount:
                          Math.floor(priceData.amount) +
                          Math.floor(discount || 0),
                      })}
                    </DiscountPrice>
                  </DiscountBlock>
                ) : (
                  <Price>{price}</Price>
                )}
              </>
            )}
            <OptionsList />
            {priceMode === 'top' ? (
              <Button disabled={!item} ref={ref} onClick={addItemToCart}>
                <FormattedMessage id="product.button.add" />
              </Button>
            ) : (
              <PriceWrapBottom>
                <Price>{price}</Price>
                <PriceWrapButton>
                  <Button
                    style={{ marginTop: 0 }}
                    disabled={!item}
                    ref={ref}
                    onClick={addItemToCart}
                  >
                    <FormattedMessage id="product.button.add" />
                  </Button>
                </PriceWrapButton>
              </PriceWrapBottom>
            )}
          </Block>
        </Row>
      </Container>
    </Section>
  );
});

export default Main;

Main.propTypes = propTypes;
Main.defaultProps = defaultProps;
