import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { colorsBar, media } from '../../../../ui/common';

const Block = styled.div`
  color: ${colorsBar.blueDark};
  display: flex;
  align-items: center;
  padding: 13px 0;
  line-height: 1.5;
  flex-shrink: 0;
  box-sizing: border-box;
  img {
    display: block;
    margin-right: 8px;
  }
  ${media.phone`
    padding: 16px 10px;
  `}
`;

function Option({ src, alt, text }) {
  return (
    <Block>
      <img src={src} alt={alt} />
      <FormattedMessage id={text} />
    </Block>
  );
}
Option.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Option;
