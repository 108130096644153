import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Section,
  Container,
  Controller,
  AppContainer,
  StaticImg,
  DynamicImg,
  DynamicImgContainer,
  ControllerBox,
  Title,
} from './styled';

import firstPicture from '../../../../images/product-page/evachill-blue/interactive-board/01.jpg';
import secondPicture from '../../../../images/product-page/evachill-blue/interactive-board/02.jpg';
import { sizes } from '../../ui/common';

const adaptValueToCssPx = (value) => `${value}px`;

function InteractiveScreen() {
  const { locale } = useIntl();
  const appRef = useRef();
  const controllerRef = useRef();
  const dynamicImgContainerRef = useRef();
  const [imgInScreenWidth, setImgInScreenWidth] = useState('100vw');

  useEffect(() => {
    const handleImgInScreenWidth = () => {
      const viewPortWidth = window.innerWidth;

      if (viewPortWidth > sizes.desktopLarge) {
        setImgInScreenWidth(adaptValueToCssPx(sizes.desktopLarge));
      } else {
        setImgInScreenWidth(adaptValueToCssPx(viewPortWidth));
      }
    };

    handleImgInScreenWidth();

    window.addEventListener('resize', handleImgInScreenWidth);

    return function cleanUpListener() {
      window.removeEventListener('resize', handleImgInScreenWidth);
    };
  }, []);

  const [screenData, setScreenData] = useState({
    x0: null,
    x100: null,
    screenWidth: null,
  });

  useEffect(() => {
    const appContainer = appRef.current;

    const x0 = appContainer.getBoundingClientRect().left;
    const screenWidth = appContainer.offsetWidth;

    setScreenData({
      screenWidth,
      x0,
      x100: x0 + screenWidth,
    });
  }, [appRef]);

  const handleMouseMove = (event) => {
    event.preventDefault();

    let newDynamigimgInScreenWidth;

    const shiftPositionController = controllerRef.current.offsetWidth / 2;
    const controller = controllerRef.current;

    if ('ontouchstart' in document.documentElement) {
      newDynamigimgInScreenWidth = adaptValueToCssPx(
        event.touches[0].clientX - screenData.x0
      );
      controller.style.left = adaptValueToCssPx(
        event.touches[0].clientX - screenData.x0 - shiftPositionController
      );

      if (event.touches[0].clientX > screenData.x100) return;
    } else {
      newDynamigimgInScreenWidth = adaptValueToCssPx(
        event.clientX - screenData.x0
      );
      controller.style.left = adaptValueToCssPx(
        event.clientX - screenData.x0 - shiftPositionController
      );

      if (event.clientX <= screenData.x0) {
        controller.style.left = 0;
        return;
      }

      if (event.clientX > screenData.x100) {
        controller.style.left = adaptValueToCssPx(screenData.screenWidth);
        return;
      }
    }

    dynamicImgContainerRef.current.style.width = newDynamigimgInScreenWidth;
  };

  const handleMouseUp = (event) => {
    event.preventDefault();
    /* for touch screens */
    document.removeEventListener('touchmove', handleMouseMove);
    document.removeEventListener('touchend', handleMouseUp);
    /* for pc */
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mousemove', handleMouseMove);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    /* for touch screens */
    document.addEventListener('touchmove', handleMouseMove);
    document.addEventListener('touchend', handleMouseUp);
    /* for pc */
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <Section>
      <Container>
        <Title locale={locale}>
          <FormattedMessage id="evachill.blue.interactive_board.title" />
        </Title>
        <AppContainer ref={appRef}>
          <ControllerBox
            onTouchStart={handleMouseDown}
            onMouseDown={handleMouseDown}
            onDragStart={() => false}
            ref={controllerRef}
          >
            <Controller />
          </ControllerBox>
          <DynamicImgContainer ref={dynamicImgContainerRef}>
            <DynamicImg width={imgInScreenWidth} src={firstPicture} />
          </DynamicImgContainer>
          <StaticImg width={imgInScreenWidth} src={secondPicture} />
        </AppContainer>
      </Container>
    </Section>
  );
}

export default InteractiveScreen;
