import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import Swiper from 'swiper';
import { FormattedMessage } from 'react-intl';
import { fonts } from '../../ui/common';
import {
  colors,
  colorsBar,
  media,
  Container,
  sizes,
} from '../../../../ui/common';
import img1 from '../../../../images/product-page/evachill-blue/slider/01.jpg';
import img2 from '../../../../images/product-page/evachill-blue/slider/02.jpg';
import img3 from '../../../../images/product-page/evachill-blue/slider/03.jpg';
import img1mob from '../../../../images/product-page/evachill-blue/slider/mob/01.jpg';
import img2mob from '../../../../images/product-page/evachill-blue/slider/mob/02.jpg';
import img3mob from '../../../../images/product-page/evachill-blue/slider/mob/03.jpg';
import Arrow from './Arrow';

const Section = styled.section`
  padding: 70px 0;
  background-color: #f9f7f6;
  ${media.tablet`
    padding: 100px 0;
  `}
`;

const MoveBtn = styled.div`
  background-color: transparent;
  border: none;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  z-index: 20;
  opacity: ${(props) => props.opacity};
`;

const ImgBox = styled.div`
  height: 300px;
  ${media.tablet`
    height: 432px;
  `}
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const NavTitle = styled.h5`
  margin: 0 0 30px;
  color: ${colorsBar.blueDark};
  text-align: center;
  font-family: ${fonts.montserrat.name};
  font-weight: ${fonts.montserrat.weight['500']};
  font-size: 22px;
  line-height: 150%;
  ${media.tablet`
    text-align: left;
  `}
`;

const NavButtons = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 130px;
`;

const NavPanel = styled.div`
  padding: 30px;
  background-color: ${colorsBar.white};
  z-index: 5;
  box-sizing: border-box;
  ${media.tablet`
    max-width: 350px;
    padding: 35px 40px;
    position: absolute;
    bottom: -2px;
    left: -2px;
  `}
`;

const SliderContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

function Slider() {
  const galleryRef = useRef();
  const nextBtn = useRef();
  const prevBtn = useRef();

  let gallerySwiper;

  useEffect(() => {
    // eslint-disable-next-line no-new
    gallerySwiper = new Swiper(galleryRef.current, {
      navigation: {
        nextEl: nextBtn.current,
        prevEl: prevBtn.current,
      },
      spaceBetween: 12,
      loop: true,
      grabCursor: true,
      preloadImages: true,
    });

    return () => {
      gallerySwiper.destroy();
    };
  }, []);

  return (
    <Section>
      <Container>
        <SliderContainer className="swiper-container" ref={galleryRef}>
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <ImgBox>
                <picture>
                  <source
                    srcSet={img1}
                    media={`(min-width: ${sizes.tablet})`}
                  />
                  <img src={img1mob} alt="slider-img" />
                </picture>
              </ImgBox>
            </div>
            <div className="swiper-slide">
              <ImgBox>
                <picture>
                  <source
                    srcSet={img2}
                    media={`(min-width: ${sizes.tablet})`}
                  />
                  <img src={img2mob} alt="slider-img" />
                </picture>
              </ImgBox>
            </div>
            <div className="swiper-slide">
              <ImgBox>
                <picture>
                  <source
                    srcSet={img3}
                    media={`(min-width: ${sizes.tablet})`}
                  />
                  <img src={img3mob} alt="slider-img" />
                </picture>
              </ImgBox>
            </div>
          </div>
          <NavPanel>
            <NavTitle>
              <FormattedMessage id="evachill.blue.slider.title" />
            </NavTitle>
            <NavButtons>
              <MoveBtn ref={prevBtn} opacity={0.2}>
                <Arrow fill={colors.color01} toLeft />
              </MoveBtn>
              <MoveBtn ref={nextBtn}>
                <Arrow fill={colorsBar.blue} />
              </MoveBtn>
            </NavButtons>
          </NavPanel>
        </SliderContainer>
      </Container>
    </Section>
  );
}

export default Slider;
