import chills from './images/chills.svg';
import electricity from './images/electricity.svg';
import touch from './images/touch.svg';
import eco from './images/eco.svg';
import whisper from './images/whisper.svg';
import ultraportable from './images/ultraportable.svg';

export default [
  {
    image: chills,
    text: 'features.chills',
  },
  {
    image: touch,
    text: 'features.button',
  },
  {
    image: electricity,
    text: 'features.electricity1',
  },
  {
    image: ultraportable,
    text: 'features.ultraportable',
  },
  {
    image: eco,
    text: 'features.eco',
  },
  {
    image: whisper,
    text: 'features.whisper',
  },
];
