import styled from 'styled-components';
import { colors, media, mediaMax, colorsBar } from '../../../../ui/common';
import { primary } from '../../ui/Button';

export const Section = styled.section`
  padding: 0 0 40px;
  overflow: hidden;
  background-color: #f9f7f6;
  padding-top: 50px;
  ${media.phone`
    padding-bottom: 70px;
  `}
  ${media.laptop`
    padding-bottom: 80px;
    padding-top 108px;
  `}
`;
export const Row = styled.div`
  ${media.laptop`
    display: flex;
  `}
`;
export const Block = styled.div`
  ${media.laptop`
    width: 40%;
  `}
`;
export const Wrap = styled.div`
  padding: 20px 0 22px;
`;
export const Rating = styled.div`
  display: flex;
  margin: 22px 0;
  span {
    margin-left: 10px;
  }
  ${media.phone`
    margin: 34px 0;
  `};
`;
export const Headline = styled.h1`
  font-weight: bold;
  font-size: 32px;
  color: ${colorsBar.blue};
  margin: 16px 0;
  ${media.phone`
    font-size: 40px;
    margin: 22px 0;
  `};
`;
export const Desc = styled.div`
  font-size: 16px;
  line-height: 1.7;
  color: ${colorsBar.blueDark};
  margin-bottom: 22px;
`;
export const Text = styled.div`
  font-weight: 300;
  font-size: 16px;
  color: ${colorsBar.blueDark};
  opacity: 0.9;
  margin: 0 0 20px;
  line-height: 1.85;
`;
export const Price = styled.div`
  color: ${colorsBar.blueDark};
  margin: 30px 0 15px;
  font-size: 32px;
  ${media.phone`
    font-size: 36px;
    line-height: 64px;
    margin: 22px 0 15px;
  `}
`;
export const Button = styled.button`
  ${primary};
  width: 100%;
  height: 60px;
  margin-top: 16px;
  background-color: ${colorsBar.blue};
  border: none;
  &:hover {
    background-color: ${colorsBar.blue};
    opacity: 0.9;
  }
  ${media.laptop`
    width: auto;
  `}
`;
export const Title = styled.h3`
  font-weight: 600;
  font-size: 16px;
  color: ${colorsBar.blueDark};
  margin: 0 0 10px;
`;
export const PriceWrapBottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;
export const PriceWrapButton = styled.div`
  width: 45%;
  ${mediaMax.phone`
     width: 100%;
  `}
`;
export const DiscountBlock = styled.div`
  display: flex;
  align-items: baseline;
  ${mediaMax.phone`
    justify-content: space-between;
  `}
`;
export const PriceOriginal = styled.div`
  color: ${colors.color01};
  margin: 15px 0;
  font-size: 40px;
  ${mediaMax.phone`
    font-size: 32px;
  `}
  ${mediaMax.iphone5`
    font-size: 28px;
  `}
`;
export const DiscountPrice = styled(PriceOriginal)`
  margin-left: 20px;
  color: ${colors.color01}7F;
  text-decoration: line-through;
  text-decoration-color: ${colors.accent};
  font-size: 30px;
  ${mediaMax.phone`
    margin-left: 0px; 
    font-size: 26px;
  `}
  ${mediaMax.iphone5`
    font-size: 20px;
  `}
`;
export const PriceAndOption = styled.div`
  display: flex;
  justify-content: space-between;

  ${mediaMax.phone`
     display: block;
  `}
`;
