import styled from 'styled-components';
import { sizes } from '../../ui/common';

export const Section = styled.section`
  background-color: ${(props) => props.backgroundColor};
  @media (min-width: 375px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media (min-width: ${sizes.phone}px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media (min-width: ${sizes.tablet}px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media (min-width: ${sizes.laptop}px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

export const TextContainer = styled.div`
  box-sizing: border-box;
  background-color: inherit;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
`;

export const Text = styled.p`
  box-sizing: border-box;
  color: ${(props) => props.color};
  font-family: 'Neue Machina';
  font-weight: normal;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  @media (min-width: ${sizes.tablet}px) {
    font-size: 26px;
  }
  @media (min-width: ${sizes.desktop}px) {
    font-size: 30px;
  }
`;
