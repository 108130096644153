import React from 'react';
import styled from 'styled-components';
import { media, Container } from '../../../../ui/common';
import img1 from '../../../../images/product-page/evachill-blue/gallery/01.jpg';
import img2 from '../../../../images/product-page/evachill-blue/gallery/02.jpg';
import img3 from '../../../../images/product-page/evachill-blue/gallery/03.jpg';
import img4 from '../../../../images/product-page/evachill-blue/gallery/04.jpg';
import img5 from '../../../../images/product-page/evachill-blue/gallery/05.jpg';

const Section = styled.section`
  background-color: #f9f7f6;
`;

const GridContainer = styled.div`
  display: grid;
  max-width: 1440px;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding-bottom: 100px;
  ${media.tablet`
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(2, minmax(1fr, 255px))
  `}
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .big {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }
`;

const GridItem = styled.div`
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

function Gallery() {
  return (
    <Section>
      <Container>
        <GridContainer>
          <GridItem className="big">
            <img src={img1} alt="img" />
          </GridItem>
          <GridItem>
            <img src={img2} alt="img" />
          </GridItem>
          <GridItem>
            <img src={img3} alt="img" />
          </GridItem>
          <GridItem>
            <img src={img4} alt="img" />
          </GridItem>
          <GridItem>
            <img src={img5} alt="img" />
          </GridItem>
        </GridContainer>
      </Container>
    </Section>
  );
}

export default Gallery;
