import styled from 'styled-components';
import { media, fonts } from '../../ui/common';
import { colorsBar } from '../../../../ui/common';

export const Img = styled.img`
  max-width: 70px;
  max-height: 70px;
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 5px 0 38px;
  padding-bottom: 30px;
  border-bottom: 1px solid #0594b366;
`;
export const Block = styled.div`
  width: 50%;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${media.tablet`
    width: 33%;
  `}
  ${media.laptop`
    margin-bottom: 30px;
  `}
`;
export const Text = styled.p`
  font-size: 16px;
  line-height: 25px;
  font-family: ${fonts.montserrat.name};
  font-weight: ${fonts.montserrat.weight['500']};
  color: ${colorsBar.blueDark};
  max-width: ${(props) => (props.locale === 'de' ? '112px' : '122px')};
  word-wrap: break-word;
  margin: 0 auto;
`;
