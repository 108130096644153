import styled from 'styled-components';
import { media, sizes, fonts } from '../../ui/common';
import { colorsBar } from '../../../../ui/common';
import mobileBackground from '../../../../images/product-page/evachill-blue/intro/mobile.jpg';
import tabletBackground from '../../../../images/product-page/evachill-blue/intro/tablet.jpg';
import desktopBackground from '../../../../images/product-page/evachill-blue/intro/desktop.jpg';

export const Section = styled.section`
  background-image: url(${mobileBackground});
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 100%;
  padding-top: 150px;
  padding-bottom: 150px;
  margin-top: 70px;
  @media (min-width: ${sizes.phone}px) {
    background-image: url(${tabletBackground});
    min-height: 640px;
    box-sizing: border-box;
  }
  @media (min-width: ${sizes.medium}px) {
    background-position: center top;
  }
  @media (min-width: ${sizes.laptop}px) {
    background-image: url(${desktopBackground});
    padding: 180px 0 211px;
    background-size: 1440px 663px;
    background-position: center bottom;
    margin-top: 70px;
  }
`;

export const TextContainer = styled.div`
  box-sizing: border-box;
  ${media.phone`
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 480px;
  `}
  ${media.medium`
    max-width: 60%;
  `}
`;

export const BigIntroTitle = styled.h1`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  margin: 0;
`;

export const IntroTitleText = styled.span`
  white-space: pre-wrap;
  color: #fff;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 50px;
  line-height: 100%;
  font-family: ${fonts.neueMachina.name};
  font-weight: ${fonts.neueMachina.weight.ultrabold};
  ${media.tablet`
    font-size: 60px;
  `};
`;

export const Tag = styled.span`
  color: ${colorsBar.blueDark};
  font-size: 20px;
  line-height: 130%;
  background-color: #fff;
  padding: 3px 4px 0px;
  margin-bottom: 28px;
  max-height: 31px;
  border-radius: 4px;
  font-style: normal;
  font-family: ${fonts.neueMachina.name};
  font-weight: ${fonts.neueMachina.weight.regular};
  ${media.tablet`
    margin-bottom: 0;
    position: absolute;
    bottom: 20%;
    right: -30%;
  `};
`;

export const IntroSubTitle = styled.h2`
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  margin-top: 0;
  margin-bottom: 5px;
  font-family: ${fonts.montserrat.name};
  font-weight: ${fonts.montserrat.weight['600']};
  text-transform: uppercase;
  max-width: 425px;
  opacity: 0.9;
  span {
    span {
      text-transform: lowercase;
      font-weight: ${fonts.montserrat.weight['500']};
    }
  }
  ${media.tablet`
    max-width: 285px; 
  `}
  ${media.medium`
    max-width: 425px; 
  `}
`;

export const IntroDescription = styled.p`
  font-size: 20px;
  line-height: 30px;
  max-width: 525px;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-family: ${fonts.montserrat.name};
  font-weight: ${fonts.montserrat.weight['400']};
  opacity: 0.9;
  ${media.tablet`
    max-width: 285px; 
  `}
  ${media.medium`
    max-width: 425px; 
  `}
`;

export const Lowercase = styled.span`
  text-transform: lowercase;
`;
