import PropTypes from 'prop-types';

export const propTypes = {
  showDesc: PropTypes.bool,
  priceMode: PropTypes.string,
  discount: PropTypes.number,
};

export const defaultProps = {
  showDesc: true,
  priceMode: 'top',
  discount: null,
};
