import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '../../ui/common';
import { Section, TextContainer, Text } from './styled';

function Disclaimer({ text, color, backgroundColor }) {
  return (
    <Section backgroundColor={backgroundColor}>
      <Container>
        <TextContainer>
          <Text color={color}>{text}</Text>
        </TextContainer>
      </Container>
    </Section>
  );
}

export default Disclaimer;

Disclaimer.propTypes = {
  text: PropTypes.node,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Disclaimer.defaultProps = {
  text: '',
  color: '',
  backgroundColor: '',
};
