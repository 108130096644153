import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Container from '../../ui/Container';
import Toggle from '../../ui/Toggle';
import img from '../../../../images/product-page/how_it_work.jpg';
import { Section, SectionTitle, List, Block, Row, Title, Text } from './styled';
import questions from './data';

// TODO DRY
function FAQ() {
  const [active, setActive] = useState(null);
  const handleClick = (index) => {
    setActive(active === index ? null : index);
  };

  return (
    <Section>
      <Container>
        <SectionTitle>
          <FormattedMessage id="faq.headline" />
        </SectionTitle>
        <List>
          {questions.map(({ title, content }, questionIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <Block key={questionIndex}>
              <Row>
                <Title>
                  <FormattedMessage id={title} />
                </Title>
                <Toggle
                  onClick={() => handleClick(questionIndex)}
                  active={active === questionIndex}
                  chillBlue
                />
              </Row>
              <Text active={active === questionIndex}>
                {content.map((contentChunk, contentIndex) =>
                  contentChunk.img ? (
                    <img
                      src={img}
                      alt="Evaporative cooling diagram"
                      // eslint-disable-next-line react/no-array-index-key
                      key={contentIndex}
                    />
                  ) : (
                    // eslint-disable-next-line react/no-array-index-key
                    <p key={contentIndex}>
                      <FormattedMessage
                        id={contentChunk.id}
                        values={contentChunk.values}
                      />
                    </p>
                  )
                )}
              </Text>
            </Block>
          ))}
        </List>
      </Container>
    </Section>
  );
}

export default FAQ;
