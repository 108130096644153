import styled from 'styled-components';
import { media } from '../ui/common';
import { colors, colorsBar } from '../../../ui/common';

export const List = styled.div`
  padding: 14px 0 0;
  margin: 0;
  white-space: pre-wrap;
  ${media.phone`
    display: flex;
    flex-wrap: wrap;
  `};
  ${media.tablet`
    padding: 36px 0 15px; 
  `};
`;
export const Block = styled.div`
  padding: 20px 20px 20px 0;
  box-sizing: border-box;
  color: ${colors.color01};
  ${media.phone`
    width: 50%;
  `};
  ${media.tablet`
    padding: 26px 26px 26px 0;
  `};
  ${media.medium`
    width: 33.33%;
  `};
  ${media.laptop`
    width: 25%;
    min-height: 137px;
  `};
`;
export const Tabs = styled.div`
  margin-top: 4px;
  display: flex;
  border-bottom: 1px solid rgba(3, 102, 99, 0.1);
  ${media.laptop`
    margin-top: 16px;
  `};
`;

const defineBorderTabColor = (params) => {
  if (params.active && params.chillBlueTheme) {
    return colorsBar.blueDark;
  }

  if (params.active && !params.chillBlueTheme) {
    return colors.color05;
  }

  return 'transparent';
};
export const Tab = styled.button.attrs(() => ({
  type: 'button',
}))`
  width: 153px;
  outline: none;
  background: transparent;
  border: none;
  padding: 14px 0;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) =>
    props.chillBlueTheme ? colorsBar.blueDark : colors.color05};
  border-bottom: 2px solid
    ${(props) =>
      defineBorderTabColor({
        active: props.active,
        chillBlueTheme: props.chillBlueTheme,
      })};
`;
export const Title = styled.div`
  font-weight: 300;
  font-size: 13px;
  color: ${(props) => (props.chillBlueTheme ? colorsBar.blueDark : '')};
`;
export const Text = styled.div`
  font-weight: 500;
  font-size: 15px;
  margin: 8px 0;
  line-height: 1.7;
  color: ${(props) => (props.chillBlueTheme ? colorsBar.blueDark : '')};
`;
export const Wrap = styled.div`
  margin: 16px 0;
  ${media.medium`
      flex: 1;
      margin: 0 32px;
  `};
`;
export const Images = styled.div`
  width: 100%;
  margin: 32px 0;
  ${media.medium`
    margin: 70px -32px;
    display: flex;
  `};
`;
