import styled from 'styled-components';
import { colors } from './common';

const Divider = styled.div`
  opacity: 0.4;
  border-top: 1px solid ${colors.color05};
  margin-top: -1px;
`;

export default Divider;
