import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Container } from '../../ui/common';
import {
  Section,
  TextContainer,
  BigIntroTitle,
  IntroTitleText,
  Tag,
  IntroDescription,
  IntroSubTitle,
  Lowercase,
} from './styled';

function Intro() {
  const intl = useIntl();

  return (
    <Section>
      <Container>
        <TextContainer>
          <BigIntroTitle>
            <IntroTitleText>
              <FormattedMessage id="evachill.blue.intro.title" />
            </IntroTitleText>
            <Tag>{intl.formatMessage({ id: 'evachill.blue.intro.tag' })}</Tag>
          </BigIntroTitle>
          <IntroSubTitle>
            <FormattedMessage
              id="evachill.blue.intro.description.top"
              values={{
                // eslint-disable-next-line react/no-unstable-nested-components
                span: (chunks) => <Lowercase>{chunks}</Lowercase>,
              }}
            />
          </IntroSubTitle>
          <IntroDescription>
            {intl.formatMessage({
              id: 'evachill.blue.intro.description.bottom',
            })}
          </IntroDescription>
        </TextContainer>
      </Container>
    </Section>
  );
}

export default Intro;
