import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropType from 'prop-types';
import styled from 'styled-components';
import { media } from '../../ui/common';
import { colors } from '../../../../ui/common';
import { Wrap, Title } from './styled';

const Bar = styled.div`
  display: flex;
  margin: 0 -8px;
  ${(props) => (props.shop ? 'justify-content: center;' : '')};
`;
const Button = styled.button`
  white-space: nowrap;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0 8px;
  color: ${({ active, error }) => {
    if (active) return colors.color05;
    return error ? '#D23F36' : colors.color01;
  }};
  transition: 0.15s ease-out;
  &:hover {
    color: ${(props) => (props.error ? colors.error : colors.color05)};
  }
  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
  ${media.laptop`
  `};
`;
const Dot = styled.div`
  position: relative;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 28px;
  border: 1px solid
    ${(props) =>
      props.error ? 'rgba(210, 63, 54, 0.4)' : 'rgba(3, 102, 99, 0.4)'};
  z-index: 0;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 24px;
    height: 24px;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    background: ${(props) => (props.active ? colors.color05 : 'transparent')};
    transition: 0.15s ease-out;
  }
  svg {
    color: ${(props) => {
      if (props.active) return 'white';
      return props.error ? colors.error : colors.color05;
    }};
  }
  &:hover:before {
    background: rgba(2, 112, 109, 0.1);
  }
`;
const Text = styled.div`
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translateX(-50%);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  box-sizing: border-box;
  color: currentColor;
  ${(props) => props.shop && `display: block; padding-top: 5px;`};
`;
const TextWrap = styled.div`
  position: relative;
  padding-bottom: 20px;
`;

function Plugs({ shop, list, active, setPlug, error }) {
  return (
    <Wrap>
      {!shop && (
        <Title>
          <FormattedMessage id="product.title.plug" />
          {active}
        </Title>
      )}
      <Bar shop={shop}>
        {list.map((plug) => {
          const isShowError = plug.available && error;

          return (
            <Button
              type="button"
              key={plug.title}
              disabled={!plug.available}
              onClick={() => setPlug(plug)}
              active={active === plug.title}
              error={isShowError}
            >
              <Dot error={isShowError} active={active === plug.title}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 30 30"
                  width="30"
                  hanging="30"
                >
                  {plug.title === 'AU' && (
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.828 10.707l-2.121 2.121M19.949 12.829l-2.121-2.122"
                    />
                  )}
                  {plug.title === 'EU' && (
                    <g>
                      <circle cx="11.5" cy="14.5" r="1.5" fill="currentColor" />
                      <circle cx="18.5" cy="14.5" r="1.5" fill="currentColor" />
                    </g>
                  )}
                  {plug.title === 'UK' && (
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.5 14.5v-4M9.5 18.5h4M17.5 18.5h4"
                    />
                  )}
                  {plug.title === 'US' && (
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.5 17.5v-5M18.5 17.5v-5"
                    />
                  )}
                  {plug.title === 'EU/UK' && (
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13 12.5C13 13.3284 12.3284 14 11.5 14C10.6716 14 10 13.3284 10 12.5C10 11.6716 10.6716 11 11.5 11C12.3284 11 13 11.6716 13 12.5ZM21 12.5C21 13.3284 20.3284 14 19.5 14C18.6716 14 18 13.3284 18 12.5C18 11.6716 18.6716 11 19.5 11C20.3284 11 21 11.6716 21 12.5ZM15 15.5C15 15.7761 15.2239 16 15.5 16C15.7761 16 16 15.7761 16 15.5V11.5C16 11.2239 15.7761 11 15.5 11C15.2239 11 15 11.2239 15 11.5V15.5ZM9 19.5C9 19.2239 9.22386 19 9.5 19H13.5C13.7761 19 14 19.2239 14 19.5C14 19.7761 13.7761 20 13.5 20H9.5C9.22386 20 9 19.7761 9 19.5ZM17.5 19C17.2239 19 17 19.2239 17 19.5C17 19.7761 17.2239 20 17.5 20H21.5C21.7761 20 22 19.7761 22 19.5C22 19.2239 21.7761 19 21.5 19H17.5Z"
                      fill="currentColor"
                    />
                  )}
                </svg>
              </Dot>
              <TextWrap>
                <Text shop={shop}>{plug.title}</Text>
              </TextWrap>
            </Button>
          );
        })}
      </Bar>
    </Wrap>
  );
}

export default Plugs;

Plugs.propTypes = {
  list: PropType.arrayOf(PropType.object).isRequired,
  active: PropType.string.isRequired,
  setPlug: PropType.func.isRequired,
  shop: PropType.bool,
  error: PropType.bool,
};

Plugs.defaultProps = {
  shop: false,
  error: false,
};
