import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container, Headline, media } from '../../ui/common';
import { colorsBar } from '../../../../ui/common';
import fbIcon from '../../../../images/social/icon-facebook.svg';
import instagramIcon from '../../../../images/social/icon-instagram.svg';
import twitterIcon from '../../../../images/social/icon-twitter.svg';

const Section = styled.div`
  background-color: #f9f7f6;
  padding-top: 70px;
  padding-bottom: 70px;
  ${media.tablet`
    padding-bottom: 100px;  
  `}
`;

const SectionTitle = styled(Headline)`
  color: ${colorsBar.blue};
  margin-bottom: 20px;
  ${media.laptop`
    margin-bottom: 30px;
  `}
`;

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items center;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
`;

const Icon = styled.a`
  width: 103px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colorsBar.white};
  cursor: pointer;
  img {
    max-width: 40px;
    max-height: 40px;
  }
  ${media.tablet`
    width: 169px;
    height: 100px;
    img {
      max-width: none;
      max-height: none;
    }
  `}
  ${media.laptop`
    width: 160px;
    height: 100px;
  `}
`;

function Share() {
  const { locale } = useIntl();

  const fbLink = `https://www.facebook.com/sharer/sharer.php?u=https://${process.env.GATSBY_DOMAIN}/${locale}/chill-the-ocean`;
  const twitterLink = `https://twitter.com/intent/tweet?url=https://${process.env.GATSBY_DOMAIN}/${locale}/chill-the-ocean/`;
  const instagramLink = `https://www.instagram.com/p/CQv1EIpHgHB/`;

  return (
    <Section>
      <Container>
        <SectionTitle>
          <FormattedMessage id="evachill.blue.share.title" />
        </SectionTitle>
        <IconsContainer>
          <Icon target="_blank" href={fbLink}>
            <img src={fbIcon} alt="icon" />
          </Icon>
          <Icon target="_blank" href={instagramLink}>
            <img src={instagramIcon} alt="icon" />
          </Icon>
          <Icon target="_blank" href={twitterLink}>
            <img src={twitterIcon} alt="icon" />
          </Icon>
        </IconsContainer>
      </Container>
    </Section>
  );
}

export default Share;
