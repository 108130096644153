import React, { useRef, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { useIntl } from 'react-intl';
import getFluid from '../utils/get-fluid';
import Info from '../components/Products/EvaChillBlue/Info';
import Intro from '../components/Products/EvaChillBlue/Intro';
import Disclaimer from '../components/Products/EvaChillBlue/Disclaimer';
import Mission from '../components/Products/EvaChillBlue/Mission';
import FAQ from '../components/Products/EvaChillBlue/FAQ';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar';
import Specifications from '../components/Products/EvaChillBlue/Specifications';
import PopUpAdd from '../components/Products/PopUpAdd';
import pagesInfo from '../domain/pages-info';
import InteractiveScreen from '../components/Products/EvaChillBlue/InteractiveScreen';
import Share from '../components/Products/EvaChillBlue/Share';
import { colorsBar } from '../ui/common';
import Slider from '../components/Products/EvaChillBlue/Slider';

export const promo = graphql`
  {
    map1: file(
      relativePath: { eq: "product-page/evachill-blue/faq/faq1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    map2: file(
      relativePath: { eq: "product-page/evachill-blue/faq/faq2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    vpreview: file(
      relativePath: { eq: "product-page/evachill/gallery/video.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    blue01: file(
      relativePath: { eq: "product-page/evachill/gallery/blue/01.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    blue02: file(
      relativePath: { eq: "product-page/evachill/gallery/blue/02.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    blue03: file(
      relativePath: { eq: "product-page/evachill/gallery/blue/03.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    blue04: file(
      relativePath: { eq: "product-page/evachill/gallery/blue/04.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;

const info = pagesInfo.evachill;

export default function ChillTheOcean() {
  const infoRef = useRef(null);
  const [selected, setData] = useState({});
  const intl = useIntl();

  return (
    <StaticQuery
      query={promo}
      render={(data) => (
        <>
          <NavBar />
          <Intro />
          <Disclaimer
            text={intl.formatMessage({
              id: 'evachill.blue.disclaimer.first.text',
            })}
            color={colorsBar.blue}
            backgroundColor="#F9F7F6"
          />
          <Mission targetRef={infoRef} />
          <Slider />
          <InteractiveScreen />
          <Info
            data={info}
            productName={info.title}
            fluids={
              new Map([
                [
                  'blue',
                  ['blue01', 'blue02', 'blue03', 'blue04'].map((key) =>
                    getFluid(data, key)
                  ),
                ],
              ])
            }
            youtube={{
              url: 'https://www.youtube.com/embed/JHEd-50uPyo',
              aspectRatio: 4 / 4,
              fluid: getFluid(data, 'vpreview'),
              title: 'evaCHILL. Be Cool. Anywhere. Anytime. YouTube video.',
            }}
            ref={infoRef}
            onChange={setData}
          />
          <Disclaimer
            text={intl.formatMessage({
              id: 'evachill.blue.disclaimer.second.text',
            })}
            color={colorsBar.white}
            backgroundColor={colorsBar.blue}
          />
          <Specifications
            type="evachill"
            images={[
              data.map1.childImageSharp.gatsbyImageData,
              data.map2.childImageSharp.gatsbyImageData,
            ]}
          />
          <Share />
          <FAQ />
          <PopUpAdd chillBlueTheme data={selected} targetRef={infoRef} />
          <Footer />
        </>
      )}
    />
  );
}
