import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col, ColOcean, Button, H3, ImgText, Bold } from './styled';
import { Container } from '../InteractiveScreen/styled';
import EvaChillBlue from '../../../../images/product-page/evachill-blue/mission/mission.jpg';
import OceanPhoto from '../../../../images/product-page/evachill-blue/mission/agency.jpg';
import TextOcean from '../../../../images/product-page/evachill-blue/mission/text-ocean-agency.svg';

function MissionAndOcean({ targetRef }) {
  const intl = useIntl();
  const missionPhoto = useRef();
  const oceanPhoto = useRef();

  const handleMissionBtnClick = () => {
    window.scrollTo(0, targetRef.current.offsetTop - 1150);
  };

  useEffect(() => {
    const setPhotoSize = () => {
      const photoWidth = missionPhoto.current.offsetWidth;
      const photoHeight = missionPhoto.current.offsetHeight;
      oceanPhoto.current.style.width = `${photoWidth}px`;
      oceanPhoto.current.style.height = `${photoHeight}px`;
    };

    setPhotoSize();

    window.addEventListener('resize', setPhotoSize);

    return () => window.removeEventListener('resize', setPhotoSize);
  }, [missionPhoto, oceanPhoto]);

  return (
    <Container>
      <Row>
        <Col ref={missionPhoto} background={EvaChillBlue} />
        <Col padding>
          <H3>{intl.formatMessage({ id: 'evachill.blue.mission.title' })}</H3>
          <p>
            <FormattedMessage
              id="evachill.blue.mission.text1"
              values={{
                // eslint-disable-next-line react/no-unstable-nested-components
                span: (chunks) => <span>{chunks}</span>,
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="evachill.blue.mission.text2"
              values={{
                // eslint-disable-next-line react/no-unstable-nested-components
                span: (chunks) => <Bold>{chunks}</Bold>,
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="evachill.blue.mission.text3"
              values={{
                // eslint-disable-next-line react/no-unstable-nested-components
                span: (chunks) => <span>{chunks}</span>,
              }}
            />
          </p>
          <Button onClick={handleMissionBtnClick}>
            {intl.formatMessage({ id: 'evachill.blue.mission.button_text' })}
          </Button>
        </Col>
      </Row>
      <Row rowReverseTablet>
        <Col background={OceanPhoto} ref={oceanPhoto}>
          <ImgText src={TextOcean} alt="photo-text" />
        </Col>
        <ColOcean padding>
          <p>{intl.formatMessage({ id: 'evachill.blue.ocean_agency.text' })}</p>
          <Button inversed>
            <a href="https://www.theoceanagency.org/">
              {intl.formatMessage({
                id: 'evachill.blue.ocean_agency.button_text',
              })}
            </a>
          </Button>
        </ColOcean>
      </Row>
    </Container>
  );
}

export default MissionAndOcean;

MissionAndOcean.propTypes = {
  targetRef: PropTypes.oneOfType([
    PropTypes.func,
    // TODO Specify correct propType
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
};
