import styled from 'styled-components';
import { colorsBar, sizes, media } from '../../../../ui/common';
import { fonts } from '../../ui/common';

export const Mission = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colorsBar.blue};
  font-family: ${fonts.neueMachina.name};
`;

export const H3 = styled.h3`
  font-weight: ${fonts.neueMachina.weight.regular};
  font-size: 60px;
  margin: 0;
  margin-bottom: 20px;
  line-height: 130%;
`;

export const Ocean = styled(Mission)`
  flex-direction: column-reverse;
`;

export const Row = styled.div`
  color: ${colorsBar.blue};
  font-family: ${fonts.neueMachina.name};
  @media (min-width: ${sizes.laptop}px) {
    display: flex;
    flex-direction: ${(props) =>
      props.rowReverseTablet ? 'row-reverse' : 'row'};
  }
`;

export const Col = styled.div`
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? '50px 15px' : '')};
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 0;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 345px;
  @media (min-width: ${sizes.tablet}px) {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    min-height: ${(props) => (props.background ? '707px' : '')};
    padding: ${(props) => (props.padding ? '50px 80px' : '')};
    align-items: flex-start;
  }
  ${media.laptop` 
    width: 50%; 
    min-height: ${(props) => (props.background ? '663px' : '')};
  `}
  @media (min-width: ${sizes.desktopLarge}px) {
    padding: ${(props) => (props.padding ? '50px 150px 55px 80px' : '')};
  }
  p {
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: ${colorsBar.blueDark};
    font-family: ${fonts.montserrat.name};
    font-weight: ${fonts.montserrat.weight['400']};
    opacity: 0.9;
    margin: 0 0 30px;
    span {
      span {
        font-weight: ${fonts.montserrat.weight['600']};
      }
    }
  }
`;

export const ColOcean = styled(Col)`
  p {
    font-size: 22px;
    line-height: 33px;
    letter-spacing: -0.01em;
    span {
      font-weight: 400;
    }
  }
`;

export const ImgText = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 158px;
  height: 156px;
  @media (min-width: ${sizes.tablet}px) {
    width: 288px;
    height: 285px;
  }
`;

export const Button = styled.div`
  background-color: ${(props) =>
    props.inversed ? colorsBar.white : colorsBar.blue};
  color: ${(props) => (props.inversed ? colorsBar.blue : colorsBar.white)};
  border: 1px solid ${colorsBar.blue};
  font-size: 14px;
  font-family: ${fonts.montserrat.name};
  font-weight: 600;
  padding: 27px 50px;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: center;
  ${media.medium`
    width: auto;
    display: inline-block;
  `}
`;

export const Bold = styled.span`
  font-weight: 600;
`;
