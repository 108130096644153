import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { List, Block, Text, Img } from './styled';
import list from './data';

function Features() {
  const { locale } = useIntl();

  return (
    <List>
      {list.map((item) => (
        <Block key={item.text}>
          <Img src={item.image} alt={item.text} />
          <Text locale={locale}>
            <FormattedMessage id={item.text} />
          </Text>
        </Block>
      ))}
    </List>
  );
}
export default Features;
