import styled from 'styled-components';
import controllerIcon from '../../../../images/product-page/evachill-blue/icon-controller.svg';
import { sizes } from '../../../../ui/media';
import { colorsBar } from '../../../../ui/common';

export const Section = styled.section`
  background-color: #f9f7f6;
`;

export const Container = styled.div`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export const Title = styled.h3`
  font-family: 'Neue Machina';
  font-weight: normal;
  font-size: 30px;
  line-height: 110%;
  margin-top: 0;
  margin-bottom: 15px;
  color: ${colorsBar.blue};
  text-align: center;
  @media (min-width: ${sizes.tablet}px) {
    margin-bottom: 40px;
    font-size: 36px;
  }
  @media (min-width: ${sizes.laptop}px) {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    font-size: ${(props) => (props.locale === 'de' ? '40px' : '50px')};
    z-index: 4;
    max-width: 1100px;
    width: 100%;
    color: ${colorsBar.white};
  }
`;

export const ControllerBox = styled.div`
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  &::before {
    content: '';
    width: 4px;
    background-color: #fff;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
`;

export const Controller = styled.div`
  cursor: pointer;
  position: absolute;
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(${controllerIcon});
  z-index: 3;
`;

export const AppContainer = styled.div`
  position: relative;
`;

export const DynamicImgContainer = styled.div`
  position: relative;
  width: 50%;
  will-change: width;
  overflow: hidden;
  line-height: 0;
`;

export const DynamicImg = styled.img`
  width: ${(props) => props.width};
  height: 100%;
  position: relative;
  z-index: 2;
  object-fit: cover;
  min-height: 330px;
  @media (min-width: ${sizes.tablet}px) {
    min-height: 430px;
  }
`;

export const StaticImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1;
  width: ${(props) => props.width};
  object-fit: cover;
`;
