import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ItemType from '../../../../domain/item-type';
import useItemsData from './use-items-data';
import useProductTrack from '../../../../hooks/use-product-track';
import Main from './Main';
import { propTypes, defaultProps } from './Props';
import Context from './Context';
import { getCoolPrivilegeItem } from '../../../Basket/CoolPrivilege';
import useLocalizedCurrency from '../../../../hooks/use-localized-currency';
import useBasket from '../../../../hooks/use-basket';
import SimpleGallery from './SimpleGallery';

const Info = React.forwardRef((props, ref) => {
  const { productName, onChange, children, youtube, fluids, data } = props;
  const { addItem } = useBasket();

  const coolPrivilegeItem = getCoolPrivilegeItem();
  const coolPrivilegeRef = useRef();
  const coolPrivilegePrice =
    coolPrivilegeItem && coolPrivilegeItem.country_prices[0]
      ? coolPrivilegeItem.country_prices[0].price.amount
      : 0;

  const {
    data: itemsData,
    setPlug,
    setColor,
    toDevice,
    toPackage,
  } = useItemsData(productName);
  const allItems = useSelector((state) => state.items.list);
  useProductTrack(productName);
  const item =
    itemsData.selectedIndex === -1
      ? null
      : itemsData.available[itemsData.selectedIndex];

  let price;
  let priceData;
  const currency = useLocalizedCurrency();
  let localizedDiff;
  let localizedPackagePrice;
  let localizedDevicePrice;

  const getFinalPrice = (priceObject) => {
    const priceValue = Number(priceObject.split(' ')[0]);
    let coolPrivilegeCost;

    if (coolPrivilegeRef.current && coolPrivilegeRef.current.checked) {
      coolPrivilegeCost = Number(coolPrivilegePrice);
    } else {
      coolPrivilegeCost = 0;
    }

    return `${(priceValue + coolPrivilegeCost).toFixed(2)} ${currency}`;
  };

  if (item) {
    if (item.type === ItemType.OrphanDevice) {
      price = getFinalPrice(item.price);
      priceData = item.priceData;
    } else {
      price = itemsData.deviceInsteadOfPackage
        ? getFinalPrice(item.devicePrice)
        : getFinalPrice(item.packagePrice);
      priceData = itemsData.deviceInsteadOfPackage
        ? item.devicePriceData
        : item.packagePriceData;
      localizedDiff = `${item.pricesDiff.split(' ')[0]} ${currency}`;
      localizedPackagePrice = `${item.packagePriceData.amount} ${currency}`;
      localizedDevicePrice = `${item.devicePriceData.amount} ${currency}`;
    }
  }
  const setPlugByTitle = ({ title }) => {
    setPlug(title);
  };

  useEffect(() => {
    if (onChange) onChange({ price, productName });
  }, [itemsData, price]);

  const addItemToCart = () => {
    const itemId = itemsData.deviceInsteadOfPackage
      ? item.deviceId
      : item.packageId;
    const variantItem = allItems.find((value) => value.id === itemId);

    if (variantItem && !coolPrivilegeRef.current.checked) {
      addItem(variantItem, true);
    }

    if (variantItem && coolPrivilegeRef.current.checked) {
      addItem(variantItem, true);
      addItem(coolPrivilegeItem, false);
    }
  };

  // TODO Wrap with useMemo()?
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const state = {
    onChange,
    productName,
    coolPrivilegeRef,
    addItemToCart,
    setPlugByTitle,
    price,
    priceData,
    currency,
    localizedDevicePrice,
    localizedDiff,
    localizedPackagePrice,
    item,
    itemsData,
    setPlug,
    setColor,
    toDevice,
    toPackage,
    fluids,
    youtube,
    data,
  };

  return (
    <>
      <Context.Provider value={state}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {children || <Main {...props} ref={ref} />}
      </Context.Provider>
      <SimpleGallery />
    </>
  );
});

export default Info;

Info.propTypes = {
  productName: PropTypes.string.isRequired,
  youtube: PropTypes.exact({
    url: PropTypes.string.isRequired,
    aspectRatio: PropTypes.number.isRequired,
    fluid: PropTypes.object.isRequired,
  }).isRequired,
  fluids: PropTypes.instanceOf(Map).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  ...propTypes,
};

Info.defaultProps = {
  data: {
    title: '',
    desc: '',
    about: '',
    review: true,
  },
  onChange: null,
  ...defaultProps,
};
