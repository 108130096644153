import React from 'react';
import styled from 'styled-components';
import { media } from '../../ui/common';
import warrantyIcon from './icons/warranty.svg';
import boxIcon from './icons/box.svg';
import Option from './Option';

const Row = styled.div`
  ${media.phone`
    display: flex;
    margin: 0 -16px;
  `}
`;

export default function OptionsList() {
  return (
    <Row>
      <Option src={warrantyIcon} alt="" text="product.options.warranty" />
      <Option src={boxIcon} alt="" text="product.options.shipping" />
    </Row>
  );
}
