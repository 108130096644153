import React from 'react';
import PropType from 'prop-types';

function Arrow({ fill, toLeft }) {
  let leftDirection = {};

  if (toLeft) {
    leftDirection = {
      transformOrigin: '50%',
      transform: 'rotate(180deg)',
    };
  }

  return (
    <svg
      style={leftDirection}
      width="55"
      height="16"
      viewBox="0 0 55 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.3432 0.92888L54.7071 7.29284C55.0976 7.68336 55.0976 8.31653 54.7071 8.70705L48.3432 15.071C47.9526 15.4615 47.3195 15.4615 46.9289 15.071C46.5384 14.6805 46.5384 14.0473 46.9289 13.6568L51.5858 8.99995H0V6.99995H51.5858L46.9289 2.34309C46.5384 1.95257 46.5384 1.3194 46.9289 0.92888C47.3195 0.538355 47.9526 0.538355 48.3432 0.92888Z"
        fill={fill}
      />
    </svg>
  );
}

export default Arrow;

Arrow.propTypes = {
  fill: PropType.string.isRequired,
  toLeft: PropType.bool,
};

Arrow.defaultProps = {
  toLeft: false,
};
